import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Rings (Kipping Ring Dips, Toes thru Rings, Skin the
Cats)`}</p>
    <p>{`then,`}</p>
    <p>{`Buy in: 20-Fifty foot shuttle runs`}</p>
    <p>{`21-Ring Dips`}</p>
    <p>{`21-CTB Pullups`}</p>
    <p>{`100ft Bear Crawl`}</p>
    <p>{`15-Ring Dips`}</p>
    <p>{`15-CTB Pullups`}</p>
    <p>{`100ft Bear Crawl`}</p>
    <p>{`9-Ring Dips`}</p>
    <p>{`9-CTB Pullups`}</p>
    <p>{`100ft Bear Crawl`}</p>
    <p>{`Cash out: 20-Fifty foot shuttle runs`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 5, our annual 4 person team competition, will take place
this year on November 4th!  Get your teams together now.  More info to
come. Truegritcomp.wordpress.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      